import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import { get } from 'lodash'
import Helmet from 'react-helmet'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import YotpoWidget from '../components/yotpo/widget'
import HeroImage from '../components/contentful/hero-image'
import { FaStore } from 'react-icons/fa'
import styles from '../scss/page.scss'

class NotFoundPage extends React.Component {  
  constructor(props) {
    super(props);
    
    const title = 'Page Not Found'
    this.seo = {
      title: title,
      meta: {
        title: title
      }
    }
    
    this.mainImage = get(this, 'props.data.mainImage.childImageSharp')
  }
  
  render() {
    return (
      <Layout containerClassName={`page page-not-found`} location={ this.props.location }>
        <SEO { ...this.seo } />
        <section className="bg-yellow-light">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 text-lg-center d-none d-lg-block">
                <Img fluid={ this.mainImage.fluid } className='lazyload' alt="CBD Oil for dogs and cats" />
              </div>
              <div className="col-sm-10 offset-sm-1 offset-lg-0 col-lg-6 text-center text-lg-left">
                <h1 className="mt-lg-5 mb-lg-1 text-primary">Oh Oh something's <br /> wrong here</h1>
                <Img fluid={ this.mainImage.fluid } alt="page-not-found" className="d-lg-none mt-4 mb-5 img-fluid" objectFit="contain"/>
                <div className="h3">We can't find the page <br className="d-lg-none"/> you're looking for. <br />Check out our FAQ's <br className="d-lg-none"/> or go back home.</div>        
                <div className="btn-container mt-4 d-none d-sm-block">
                  <Link to="/" 
                        className="btn btn-success mr-lg-2 mr-xl-3">Home</Link>
                  <Link to="/faqs/" 
                        className="btn btn-success">FAQ's</Link>
                </div>      
                <div className="btn-container mt-4 d-sm-none">
                  <Link to="/" 
                        className="btn btn-success btn-large">Home</Link>
                  <br className="d-sm-none" />
                  <Link to="/faqs/" 
                        className="btn btn-success btn-large mt-3">FAQ's</Link>
                </div>      
              </div>
            </div>
          </div>
          {/* <div className="container">

            <div className="row">
              <div className="col-12 col-md-6 text-center text-md-right">
                <Img fluid={ this.mainImage.fluid } className='lazyload' alt="Dog Resting" />
              </div>
              <div className="col-12 col-md-6 text-center text-md-left">
                <h1 className="text-primary">Oh Oh something's <br /> wrong here</h1>
                <div className="h3">
                  We can't find the page you're  <br />looking for. Check out our FAQ's <br /> or go back home.
                </div>
                <div className="btn-container mt-4 d-none d-sm-block">
                  <Link to="/" 
                        className="btn btn-success mr-lg-2 mr-xl-3">Home</Link>
                  <Link to="/faqs/" 
                        className="btn btn-success">FAQ's</Link>
                </div>      
                <div className="btn-container mt-4 d-sm-none">
                  <Link to="/" 
                        className="btn btn-success btn-large">Home</Link>
                  <br className="d-sm-none" />
                  <Link to="/faqs/" 
                        className="btn btn-success btn-large mt-3">FAQ's</Link>
                </div>      
              </div>
            </div>
          </div> */}
        </section>
      </Layout>
    )
  }
}

export default NotFoundPage

export const NotFoundPageQuery = graphql`
  query NotFoundPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    mainImage: file(relativePath: { eq: "not-found.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`